import { Icon } from 'antd';
import { Drawer, List, NavBar } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.min.css';
import 'antd/dist/antd.min.css';
import React, { useState } from 'react';
import { HashRouter, BrowserRouter, Link, Redirect, Route } from "react-router-dom";
import styles from './App.module.scss';
import Page7DeSetembro2020 from "./Page7DeSetembro2020";

// import PageIndex from "./PageIndex";
import PagePartituras from "./PagePartituras";

export default function App() {
  const { documentElement: { clientHeight } } = document;

  const menuState = MenuState();

  if (window.location.href.includes('www.taiyo.band/ensaio')) {
    window.location.replace('https://meet.google.com/qrx-wwbr-ipa');
  }

  return (
    <div>
      <BrowserRouter>
        <NavBar
          icon={<Icon style={{ cursor: 'pointer' }} type="menu" />}
          onLeftClick={menuState.toggle}
        >
          Taiyo
        </NavBar>
        <Drawer
          className={styles.Drawer}
          style={{ minHeight: clientHeight }}
          enableDragHandle={true}
          sidebar={<MenuSideBar onClick={menuState.hide} />}
          sidebarStyle={{ backgroundColor: '#FFF' }}
          open={menuState.isOpen}
          onOpenChange={menuState.toggle}
        >
          <div>
            {/*<Route path="/" exact component={PageIndex}/>*/}
            <Route path="/ensaio" exact component={() => {
              window.location.replace('https://meet.google.com/sqr-ztfk-ket');
              return null;
            }} />
            <Route path="/shuninkai" exact component={() => {
              window.location.replace('https://meet.google.com/ttm-cwve-cgf');
              return null;
            }} />
            <Route path="/daimoku" exact component={() => {
              window.location.replace('https://meet.google.com/rqj-zzmj-jaa');
              return null;
            }} />
            <Route path="/aniversario" exact component={() => {
              window.location.replace('https://meet.google.com/cdh-vghv-hfg');
              return null;
            }} />
            <Route path="/info" exact component={PageInfo} />
            <Route path="/partituras" exact component={PagePartituras} />
            <Route path="/7-de-setembro-2021" exact component={Page7DeSetembro2020} />
            <Route path="*" children={<Redirect to={'/partituras'} />} />
          </div>
        </Drawer>
      </BrowserRouter>
    </div>
  );
}

function MenuState() {
  const [isOpen, setIsOpen] = useState(false);
  return {
    isOpen,
    toggle: () => setIsOpen(!isOpen),
    hide: () => setIsOpen(false),
  }
}

function MenuSideBar({ onClick }) {
  return (
    <List onClick={onClick}>
      <List.Item multipleLine>
        <Link to="/partituras">Partituras</Link>
      </List.Item>
      <List.Item multipleLine>
        <Link to="/7-de-setembro-2021">7 de Set. 2021</Link>
      </List.Item>
    </List>
  )
}

function PageInfo() {
  return (
    <div className={styles.info}>
      <div className={styles.infoContainer}>
        <span className={styles.title}>Próximo Ensaio:</span>
        <span><strong>Data:</strong> 29/12 de 2019 (Domingo)</span>
        <span><strong>Horário:</strong> 14h00 às 18h00</span>
        <span><strong>Local:</strong> Centro Cultural Campinas - Rua Espanha, 437</span>

        <span>Ensaiar as partituras:</span>
        <ul>
          <li><a href={'https://drive.google.com/open?id=1DuAOBeO5VgwkhWzsR9JAp6Tc39xYSV8K'}>Gonna Fly Now</a></li>
        </ul>
      </div>
    </div>
  )
}
