import React from 'react';
import InformativoKurenai from './assets/informativo-video-kurenai.pdf';
import AudioKurenai from './assets/Kurenai no Uta.mp3';
import Clarinete1 from './assets/kurenai-no-uta/Clarinete 1.pdf';
import Clarinete2e3 from './assets/kurenai-no-uta/Clarinete 2 e 3.pdf';
import Euphonium from './assets/kurenai-no-uta/Euphonium.pdf';
import Flauta from './assets/kurenai-no-uta/Flauta.pdf';
import Grade from './assets/kurenai-no-uta/Grade.pdf';
import PercussaoBassDrum from './assets/kurenai-no-uta/Percussao - Bass Drum.pdf';
import PercussaoCymbal from './assets/kurenai-no-uta/Percussao - Cymbal.pdf';
import PercussaoGlockenspiel from './assets/kurenai-no-uta/Percussao - Glockenspiel.pdf';
import PercussaoSnareDrum from './assets/kurenai-no-uta/Percussao - Snare Drum.pdf';
import PercussaoTimpani from './assets/kurenai-no-uta/Percussao - Timpani.pdf';
import SaxAlto from './assets/kurenai-no-uta/Sax Alto 1 e 2.pdf';
import SaxBaritono from './assets/kurenai-no-uta/Sax Baritono.pdf';
import SaxTenor from './assets/kurenai-no-uta/Sax Tenor.pdf';
import Trombone1 from './assets/kurenai-no-uta/Trombone 1 e 2.pdf';
import Trombone2e3 from './assets/kurenai-no-uta/Trombone 3.pdf';
import Trompa1e3 from './assets/kurenai-no-uta/Trompa 1 e 3.pdf';
import Trompa2e4 from './assets/kurenai-no-uta/Trompa 2 e 4.pdf';
import Trompete1 from './assets/kurenai-no-uta/Trompete 1.pdf';
import Trompete2e3 from './assets/kurenai-no-uta/Trompete 2 e 3.pdf';
import Tuba from './assets/kurenai-no-uta/Tuba.pdf';
import AudioOngakutaiKun from './assets/ongakutai-kun-audio-base.mp3';
import styles from './Page7DeSetembro2020.module.scss';

export default function Page7DeSetembro2020() {
  return (
    <div className={styles.RootContainer}>
      <div className={styles.Informativo}>
        <h1>7 de Setembro 2021</h1>

        <h2>Partituras Herois do Novo Século</h2>
        <div className={styles.Partituras}>
          <a target="_blank" rel="noopener noreferrer" href={'https://drive.google.com/drive/folders/0B4_U7CmDTiecN3lNZ0o2eVBfVXc?resourcekey=0-rvGZ405N_xMep1NJbFBibw&usp=sharing'} >Link</a>
        </div>
        <h2>Audio Base Herois do Novo Século</h2>
        <div className={styles.Partituras}>
          <a target="_blank" rel="noopener noreferrer" href={'https://drive.google.com/file/d/18wmkqRqVkM78BbyY43afq3qYubdUyqGG/view?usp=sharing'} >Download</a>
        </div>
      </div>
    </div>
  )
}
