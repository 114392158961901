import React from 'react';

import styles from './PagePartituras.module.scss';

export default function PagePartituras() {
  return (
    <div
      className={styles.Root}
      dangerouslySetInnerHTML={{
        __html: `<iframe
          src="https://drive.google.com/embeddedfolderview?id=0B4_U7CmDTiecWHVPUk1rcVM1XzQ&resourcekey=0-h_qSgpMkib32oCHgU_BuUw#list"
          width="100%"
          height="100%"
       ></iframe>`
      }}/>
  )
}
